dmx.Component('data-view', {

    initialData: {
        data: [],
        page: 1,
        pages: 1,
        items: 0,
        sort: {
            on: '',
            dir: 'asc'
        },
        has: {
            first: false,
            prev: false,
            next: false,
            last: false
        }
    },

    attributes: {
        data: {
            type: [Array, Object],
            default: []
        },

        filter: {
            type: String,
            default: ''
        },

        page: {
            type: Number,
            default: 1
        },

        pageSize: {
            type: Number,
            default: 0
        },

        sortOn: {
            type: String,
            default: ''
        },

        sortDir: {
            type: String,
            default: 'asc',
            validate: function(val) {
                return /^(asc|desc)$/i.test(val);
            }
        }
    },

    methods: {
        select: function(page) {
            this.setPage(Number(page));
        },

        first: function() {
            this.page = 1;
            this.setPage(1);
        },

        prev: function() {
            var page = this.page - 1;
            if (page < 1) page = 1;
            if (page > this.data.pages) page = this.data.pages;
            this.page = page;
            this.setPage(page);
        },

        next: function() {
            var page = this.page + 1;
            if (page < 1) page = 1;
            if (page > this.data.pages) page = this.data.pages;
            this.page = page;
            this.setPage(page);
        },

        last: function() {
            var page = this.data.pages;
            this.page = page;
            this.setPage(page);
        },

        sort: function(prop, dir) {
            this.props.sortOn = prop;
            this.props.sortDir = (dir && dir.toLowerCase() == 'desc' ? 'desc' : 'asc');
            this._update();
        }
    },

    render: function(node) {
        this.items = [];
        this.filtered = [];

        this.setData(this.props.data);
        this.setPage(this.props.page);

        this.$parse();
    },

    beforeUpdate: function(idents) {
        if (this.props.filter) {
            this._needUpdate = !this.$canSkip({
                idents: dmx.getIdents(this.props.filter)
            }, idents);
        }
    },

    update: function(props, fields) {
        if (fields.has('data')) {
            this.setData(this.props.data);
        }

        if (fields.has('page')) {
            this.setPage(this.props.page);
        }

        if (fields.size) {
            // if any prop was updated
            this._needUpdate = true;
        }
    },

    updated: function() {
        if (this._needUpdate) this._update();
    },

    _update: function() {
        this._needUpdate = false;

        this.filtered = this.items.filter(item => {
            if (!this.props.filter) return true;
            return dmx.parse(this.props.filter, dmx.DataScope(item, this));
        });

        if (this.props.sortOn) {
            let on = this.props.sortOn;
            let dir = this.props.sortDir.toLowerCase()
            let rev = direction == 'desc';
            
            this.filtered.sort((a, b) => {
                if (rev) {
                    return a[on] > b[on] ? -1 : a[on] < b[on] ? 1 : 0;
                } else {
                    return a[on] < b[on] ? -1 : a[on] > b[on] ? 1 : 0;
                }
            });
            
            this.set('sort', { on, dir });
        }

        if (this.filtered.length) {
            let pageSize = this.props.pageSize;

            this.set('pages', pageSize ? Math.ceil(this.filtered.length / pageSize) : 1);
        } else {
            this.set('pages', 1);
        }

        this.set('items', this.filtered.length);

        this.setPage(this.props.page);
    },

    setData: function(data) {
        this.items = dmx.repeatItems(data);
    },

    setPage: function(page) {
        const size = +this.props.pageSize;
        const pages = this.data.pages;

        page = page < 1 ? 1 : page > pages ? pages : page;

        this.set('page', page);
        this.set('data', size ? this.filtered.splice((page - 1) * size, size) : this.filtered);
        this.set('has', {
            first: page > 1,
            prev: page > 1,
            next: page < pages,
            last: page < pages
        });
    }
});
